var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('bread'),_c('div',{staticClass:"center-content"},[_c('div',{staticClass:"iq-card-body",staticStyle:{"height":"100vh"}},[_vm._m(0),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"60px 120px"}},[_c('div',{staticStyle:{"background-color":"rgb(95, 201, 201)","width":"600px","height":"500px"}}),_c('div',{staticStyle:{"width":"400px","height":"500px","text-align":"center"}},[_c('h2',{staticStyle:{"margin-left":"80px"}},[_vm._v("请您使用公户支付应付金额！")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px","color":"tomato"}},[_vm._v("支付账号需要与医院主体一致")]),_c('div',{staticStyle:{"width":"100%"}},[_c('el-button',{staticStyle:{"margin-top":"40px"},attrs:{"type":"primary"}},[_vm._v("查看订单")]),_c('el-button',{staticStyle:{"margin-top":"40px","margin-left":"80px"},attrs:{"type":"primary"}},[_vm._v("复制")])],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-top"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('h3',[_vm._v(" 收款账号:")]),_c('div',{staticStyle:{"margin-left":"40px"}},[_vm._v("河南千越医疗科技有限公司")])]),_c('div',{staticStyle:{"display":"flex"}},[_vm._v("应付金额： "),_c('div',{staticStyle:{"color":"crimson"}},[_vm._v("23113")]),_vm._v("元")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('h3',[_vm._v("收款日期：")]),_c('div',{staticStyle:{"margin-left":"30px"}},[_vm._v("2021-01-21 12：22：34")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px"}},[_c('h3',[_vm._v("支付金额")]),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v("21312元")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px"}},[_c('h3',[_vm._v("开户名称")]),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v("河南千越医疗科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px"}},[_c('h3',[_vm._v("账户信息")]),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v("1711 0143 0910 0016 304")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px"}},[_c('h3',[_vm._v("开户地址")]),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v("中国工商银行漯河市青山路支行")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-top":"40px","margin-left":"80px"}},[_c('h3',[_vm._v("备注信息")]),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v("软件服务费")])])
}]

export { render, staticRenderFns }